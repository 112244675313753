import moment, { Moment } from 'moment';

import {
    DATE_DEFAULT_FORMAT,
    DATE_YEARLY_DEFAULT_FORMAT,
    DATETIME_DEFAULT_FORMAT,
    TIME_DEFAULT_FORMAT,
} from 'consts/dates';

export function getFormattedDate(date: Moment, format: string = 'datetime'): string {
    switch (format) {
        case 'datetime':
            format = DATETIME_DEFAULT_FORMAT;
            break;
        case 'date':
            format = DATE_DEFAULT_FORMAT;
            break;
        case 'dateYearly':
            format = DATE_YEARLY_DEFAULT_FORMAT;
            break;
        case 'time':
            format = TIME_DEFAULT_FORMAT;
            break;
    }
    return moment(date).format(format);
}