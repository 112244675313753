import ApiCourseCategory from 'types/api/CourseCategory';
import { Option } from 'types/options';

import Model from 'models/Model';

export default class CourseCategory implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiCourseCategory) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }

    getOption = (): Option<CourseCategory> => {
        return {
            value: this,
            label: this.name,
        };
    };
}