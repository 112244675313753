import ApiLead from 'types/api/Lead';
import { Option } from 'types/options';

import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

export default class Lead implements Model {
    id: string;
    name: string;
    slug: string;
    bio: string;
    description: string;
    avatar: FileUpload;

    constructor(data: ApiLead) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.bio = data.bio;
        this.description = data.description;
        this.avatar = data.avatar && new FileUpload(data.avatar);
    }

    getOption = (): Option<Lead> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
