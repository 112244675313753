import ApiCourseScheduleCustomer from 'types/api/CourseScheduleCustomer';
import { ApiResources } from 'types/apiResources';
import { Cookies } from 'types/cookies';
import { Endpoints } from 'types/endpoints';

import CourseScheduleCustomer from 'models/CourseScheduleCustomer';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';
import { getProvider } from 'services/Cookies';

export interface CustomerCreateParams {
    scheduleId?: string;
    courseId?: string;
}
export interface CustomerCreateResponse {
    [ApiResources.CourseScheduleCustomer]: CourseScheduleCustomer;
}
export const customerCreate = async (params: CustomerCreateParams): Promise<CustomerCreateResponse> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.CustomerCourseScheduleCustomer, params),
        requestParams: params,
        headers: {
            'authorization': `Bearer ${getProvider().get(Cookies.AuthToken) || ''}`,
        },
    });

    const data = response?.payload?.data;
    const resource: ApiCourseScheduleCustomer = data && data[ApiResources.CourseScheduleCustomer];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.CourseScheduleCustomer]: new CourseScheduleCustomer(resource),
    };
};
