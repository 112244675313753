export function formatPrice(price: number = 0): number {
    return Math.round(price * 100) / 100;
}

export function displayPrice(price: number = 0): string {
    const base =  `${formatPrice(price)}`;

    return `${base} zł`;
}

export function changeToCent(price: number = 0): number {
    return price * 100;
}

export function changeFromCent(price: number = 0): number {
    return formatPrice(
        price / 100
    );
}
