import ApiCourseScheduleCustomer from 'types/api/CourseScheduleCustomer';
import { Option } from 'types/options';

import CourseSchedule from 'models/CourseSchedule';
import Model from 'models/Model';

export default class CourseScheduleCustomer implements Model {
    id: string;
    courseSchedule: CourseSchedule;

    constructor(data: ApiCourseScheduleCustomer) {
        this.id = data.id;
        this.courseSchedule = data.courseSchedule && new CourseSchedule(data.courseSchedule);
    }

    getOption = (): Option<CourseScheduleCustomer> => {
        return {
            value: this,
            label: this.courseSchedule?.course?.name,
        };
    };
}