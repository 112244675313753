import ApiCourse from 'types/api/Course';
import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';
import ListCollection from 'types/redux/ListCollection';

import Course from 'models/Course';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams extends ListParamsDefault {
    search?: string;
    categoryId?: string;
    partnerId? : string;
    leadId? : string;
}
export interface ListResponse {
    [ApiResources.Courses]: ListCollection<Course>;
}
export const list = async (params: ListParams): Promise<ListResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCourses, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ListCollection<ApiCourse> = data && data[ApiResources.Courses];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Courses]: {
            ...resource,
            elements: resource.elements.map((element) => new Course(element)),
            isLoaded: true,
            isLoading: false,
            meta: resource?.meta,
        },
    };
};

export interface SingleParams {
    slug: string;
}
export interface SingleResponse {
    [ApiResources.Course]: Course;
}
export const single = async (params: SingleParams): Promise<SingleResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCourse, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiCourse = data && data[ApiResources.Course];

    if (!data || !resource) {
        return null;
    }

    return {
        [ApiResources.Course]: new Course(resource),
    };
};
