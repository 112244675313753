import Component from './component';

export interface NavigationVisibility {
    footer: boolean;
    navigation: boolean;
}

export interface Props {
    children?: any;
    navigationVisibility?: NavigationVisibility;
}

Component.defaultProps = {
    children: null,
    navigationVisibility: {
        footer: true,
        navigation: true,
    },
};

export default Component;
