import ApiFileUpload from 'types/api/FileUpload';

import Model from 'models/Model';

import { IMAGE_PLACEHOLDER } from '../consts/images';


export default class FileUpload implements Model {
    id: string;
    fileName: string;
    mimeType: string;
    imageUrl: string;
    altText?: string;

    constructor(data: ApiFileUpload) {
        this.id = data.id;
        this.fileName = data.fileName;
        this.mimeType = data.mimeType;
        this.imageUrl = data.imageUrl;
        this.altText = data.altText;
    }
}

export const getPlaceholder = (): FileUpload => {
    return new FileUpload({
        id: `${Date.now()}`,
        imageUrl: IMAGE_PLACEHOLDER,
        mimeType: null,
        fileName: null,
    });
};

