import moment, { Moment } from 'moment';

import ApiCourseSchedule from 'types/api/CourseSchedule';
import { Option } from 'types/options';

import Model from 'models/Model';

import { getFormattedDate } from 'utils/date';
import { changeFromCent, displayPrice } from 'utils/math';

import Course from './Course';
import Lead from './Lead';

export enum courseScheduleTypes {
    Online = 'online',
    Offline = 'private',
}

export default class CourseSchedule implements Model {
    id: string;
    date: Moment;
    dateDisplay: string;
    city: string;
    address: string;
    type: string;
    maxSize: number;
    price: number;
    lead: Lead;
    course: Course;
    priceDisplay: string;
    courseDuration: string;

    constructor(data: ApiCourseSchedule) {
        this.id = data.id;
        this.city = data.city;
        this.address = data.address;
        this.type = data.type;
        this.maxSize = data.maxSize;
        this.price = changeFromCent(data.price);
        this.course = data.course && new Course(data.course);
        this.lead = data.lead && new Lead(data.lead);
        this.date = data.date && moment.utc(data.date).local();
        this.dateDisplay = this.date && getFormattedDate(this.date, 'datetime');
        this.priceDisplay = this.price && displayPrice(this.price);
        this.courseDuration = data.courseDuration;
    }

    getOption = (): Option<CourseSchedule> => {
        return {
            value: this,
            label: this.course?.name,
        };
    };
}
