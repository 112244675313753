import ApiCustomerLikedCourse from 'types/api/CustomerLikedCourse';
import { ApiResources } from 'types/apiResources';
import { Cookies } from 'types/cookies';
import { Endpoints } from 'types/endpoints';

import CustomerLikedCourse from 'models/CustomerLikedCourse';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';
import { getProvider } from 'services/Cookies';

export interface PublicCreateParams {
    id: string;
    courseId: string;
}
export interface PublicCreateResponse {
    [ApiResources.CustomerLikedCourse]: CustomerLikedCourse;
}
export const customerUpdate = async (params: PublicCreateParams): Promise<PublicCreateResponse> => {
    const response = await request({
        method: 'PUT',
        path: withVariables(Endpoints.CustomerCustomerLikedCourse, params),
        requestParams: params,
        headers: {
            'authorization': `Bearer ${getProvider().get(Cookies.AuthToken) || ''}`,
        },
    });

    const data = response?.payload?.data;
    const resource: ApiCustomerLikedCourse = data && data[ApiResources.CustomerLikedCourse];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.CustomerLikedCourse]: new CustomerLikedCourse(resource),
    };
};

export interface PublicDeleteParams {
    id: string;
}

export const customerDelete = async (params: PublicDeleteParams): Promise<PublicCreateResponse> => {
    const response = await request({
        method: 'DELETE',
        path: withVariables(Endpoints.CustomerCustomerLikedCourse, params),
        requestParams: params,
        headers: {
            'authorization': `Bearer ${getProvider().get(Cookies.AuthToken) || ''}`,
        },
    });

    const data = response?.payload?.data;
    const resource: ApiCustomerLikedCourse = data && data[ApiResources.CustomerLikedCourse];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.CustomerLikedCourse]: new CustomerLikedCourse(resource),
    };
};
