import { useContext } from 'react';
import { useRouter } from 'next/router';

import { ApiResources } from 'types/apiResources';
import { Routes } from 'types/routes';

import Course from 'models/Course';
import CourseScheduleCustomer from 'models/CourseScheduleCustomer';

import { withVariables } from 'utils/string';

import { customerCreate as publicCreateCourseScheduleCustomer } from 'requests/courseScheduleCustomers';

import ProfileContext from 'components/context/Profile';

const useJoinCourseSchedule = () => {
    const router = useRouter();
    const profileCtx = useContext(ProfileContext);
    const isLogged = profileCtx?.profile;

    const joinCourseSchedule = (course: Course, scheduleDateId?: string) => {
        const scheduleId = scheduleDateId || course?.courseSchedules?.[0]?.id;

        if (!isLogged) {
            return router.push({
                pathname: withVariables(Routes.PublicCustomerLogin, { slug: course.slug }),
                query: { courseId: course.id, scheduleId },
            });
        }

        return publicCreateCourseScheduleCustomer({
            courseId: course.id,
            scheduleId,
        }).then((res) => {
            profileCtx.updateProfile({
                ...isLogged,
                courseScheduleCustomers: [
                    ...isLogged.courseScheduleCustomers,
                    ...[new CourseScheduleCustomer(res[ApiResources.CourseScheduleCustomer])],
                ],
            });
        });
    };

    return { joinCourseSchedule };
};

export default useJoinCourseSchedule;
