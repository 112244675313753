import Model from 'models/Model';

export default class CoursePartner implements Model {
    id: string;
    name: string;
    slug: string;
    description: string;

    constructor(data: CoursePartner) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
    }
}
