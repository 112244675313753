import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 4em 0 8em;
    min-height: 400px;

    .headline {
        color: ${vars.colorTextWhite};
        font-size: 3.4em;
        text-align: center;
        margin-bottom: 1em;
    }

    .courses {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4em;
    }

    @media all and (max-width: ${vars.mobileL}) {
        .courses {
            gap: 0;
        }
    }
`;
