import { FunctionComponent } from 'react';

import PointedArrowRightLight from 'theme/icons/pointed-arrow-right-light.svg';

import CourseTile from 'components/content/blocks/CourseTile';
import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';

import StyledComponent from './styles';
import { Props } from './types';

const SectionCourseTiles: FunctionComponent<Props> = ({
    translations,
    courses,
    onLike,
    onJoin,
    visibleMoreCoursesButton,
    onMoreCourses,
}) => {
    return (
        <StyledComponent className="section-courses">
            {translations?.headline && (
                <div className="headline">
                    <h2>
                        {translations?.headline}
                    </h2>
                </div>
            )}
            <div className="courses">
                {courses?.elements?.map((course, idx) => (
                    <CourseTile
                        key={course?.id || idx}
                        course={course}
                        translations={{
                            ctaButton: translations?.courseCtaButton,
                            alreadyScheduledButton: translations?.alreadyScheduledButton,
                        }}
                        onLike={onLike}
                        onJoin={onJoin}
                    />
                ))}
            </div>
            {visibleMoreCoursesButton && (
                <div className="cta-more-courses">
                    <Button
                        style={ButtonStyles.Primary}
                        variant={ButtonVariants.Tertiary}
                        label="Zobacz Więcej"
                        icon={{
                            type: ButtonIconTypes.Image,
                            value: PointedArrowRightLight.src,
                            position: 'after',
                        }}
                        onClick={onMoreCourses}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default SectionCourseTiles;
