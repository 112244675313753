import { FunctionComponent, useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import moment from 'moment';

import { Routes } from 'types/routes';

import Course from 'models/Course';

import { withVariables } from 'utils/string';

import Favorite from 'theme/icons/favorite.svg';
import FavoriteSelected from 'theme/icons/favorite_selected.svg';
import PointedArrowRight from 'theme/icons/pointed-arrow-right.svg';

import ProfileContext from 'components/context/Profile';
import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';

import StyledComponent from './styles';
import { Props } from './types';

const BlockCourseTile: FunctionComponent<Props> = ({ translations, course, onLike, onJoin }) => {
    const router = useRouter();
    const profileCtx = useContext(ProfileContext);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onMoreDates = (passedCourse: Course): void => {
        router.push(withVariables(Routes.PublicCourse, { slug: passedCourse.slug }));
    };

    const currentDate = moment().format('YYYY-MM-DD HH:mm');
    const schedules = course?.courseSchedules
        ?.map(({ date, dateDisplay, id }) => ({
            id,
            date: moment(date).format('YYYY-MM-DD HH:mm'),
            dateDisplay,
        }))
        ?.sort((a, b) => moment(a.date).unix() - moment(b.date).unix())
        ?.filter(dates => moment(dates.date).isAfter(moment(currentDate)));

    const isLiked = profileCtx?.profile?.customerLikedCourses?.find((c) => c?.course?.id === course?.id);
    const alreadyScheduledButton = profileCtx?.profile?.courseScheduleCustomers?.find(({ id }) => id === schedules[0]?.id) ||
        profileCtx?.profile?.courseScheduleCustomers?.find(({ courseSchedule }) => courseSchedule?.id === schedules[0]?.id);

    // workaround for loading button
    useEffect(() => {
        if (alreadyScheduledButton) {
            setLoading(false);
        }
    }, [alreadyScheduledButton]);

    return (
        <StyledComponent className="block-course-tile">
            <div
                className="course"
            >
                <div className="course__image">
                    <Image
                        className="course__image__image"
                        layout="fill"
                        src={course?.image?.imageUrl || '/images/home/palceholder_card_image.png'}
                        alt="card"
                        onLoad={() => imageLoaded ? null : setImageLoaded(true)}
                        onClick={() => onMoreDates(course)}
                    />
                    {imageLoaded && (
                        <Image
                            className="course__image__image-background"
                            layout="fill"
                            src={course?.image?.imageUrl || '/images/home/palceholder_card_image.png'}
                            alt="bg"
                        />
                    )}
                    <div className="course__image__favorite">
                        <Button
                            className="gradient-180"
                            style={ButtonStyles.None}
                            icon={{
                                type: ButtonIconTypes.Image,
                                value: isLiked ? FavoriteSelected.src : Favorite.src,
                                position: 'after',
                            }}
                            onClick={() => onLike(course)}
                        />
                    </div>
                    <div className="course__image__label">
                        {course?.category?.name}
                    </div>
                    {schedules[0]?.dateDisplay && (
                        <div className="course__image__date">
                            <small>
                                Terminy od:
                            </small>
                            {' '}
                            {schedules[0]?.dateDisplay}
                        </div>
                    )}
                </div>
                <div className="course__content">
                    <div
                        className="course__content__title"
                        onClick={() => onMoreDates(course)}
                    >
                        {course?.name}
                    </div>
                    <div className="course__content__price">
                        <small>
                            od
                        </small>
                        <span className="price">
                            {course?.priceFromDisplay}
                        </span>
                    </div>
                    <div className="course__content__cta">
                        <Button
                            className="gradient-180"
                            style={ButtonStyles.Primary}
                            variant={ButtonVariants.Primary}
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                onJoin(course, schedules[0]);
                            }}
                            disabled={alreadyScheduledButton}
                        >
                            {alreadyScheduledButton ? translations?.alreadyScheduledButton : translations?.ctaButton}
                        </Button>
                    </div>
                    <div className="course_content__more">
                        <Button
                            style={ButtonStyles.Secondary}
                            variant={ButtonVariants.Tertiary}
                            label="Sprawdź ofertę"
                            icon={{
                                type: ButtonIconTypes.Image,
                                value: PointedArrowRight.src,
                                position: 'after',
                            }}
                            onClick={() => onMoreDates(course)}
                        />
                    </div>
                </div>
            </div>
        </StyledComponent>
    );
};

export default BlockCourseTile;
