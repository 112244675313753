import { useContext } from 'react';
import { useRouter } from 'next/router';

import { ApiResources } from 'types/apiResources';
import { Routes } from 'types/routes';

import Course from 'models/Course';

import { withVariables } from 'utils/string';

import {
    customerDelete as deletePublicCustomerLikedCourse,
    customerUpdate as createPublicCustomerLikedCourse,
} from 'requests/customerLikedCourses';

import ProfileContext from 'components/context/Profile';
const useLikeCourse = () => {
    const router = useRouter();
    const profileCtx = useContext(ProfileContext);
    const isLogged = profileCtx?.profile;

    const likeCourse = (course: Course) => {
        const isLiked = isLogged?.customerLikedCourses?.find((c) => c?.course?.id === course?.id);

        if (!isLogged) {
            return router.push({
                pathname: withVariables(Routes.PublicCustomerLogin, { slug: course.slug }),
                query: { likedCourseId: course.id },
            });
        }

        if (isLiked) {
            return deletePublicCustomerLikedCourse({
                id: isLiked.id,
            }).then(() => {
                const removedDeleted = isLogged?.customerLikedCourses?.filter((c) => c.id !== isLiked.id);
                profileCtx.updateProfile({ ...isLogged, customerLikedCourses: removedDeleted });
            });
        }

        return createPublicCustomerLikedCourse({
            id: course.id,
            courseId: course.id,
        }).then((res) => {
            profileCtx.updateProfile({
                ...isLogged,
                customerLikedCourses: [...isLogged.customerLikedCourses, res[ApiResources.CustomerLikedCourse]],
            });
        });
    };

    return { likeCourse };
};

export default useLikeCourse;
