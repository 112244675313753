import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    .course {
        background-color: ${vars.backgroundWhite};
        flex: 1 1 360px;
        width: 400px;
        margin: 0 auto 4em;
        border-radius: 0.4em;
        height: 38em;

        &__image {
            position: relative;
            height: 226px;
            min-width: 100%;
            z-index: 1;
            border-radius: 0.4em 0.4em 0 0;
            overflow: hidden;

            img {
                height: 100%;
                min-width: 100%;
                max-width: 100%;
            }

            &__image {
                cursor: pointer;
                z-index: 0;
                object-fit: contain;

                &-background {
                    z-index: -1;
                    transform: scale(1.4);
                    filter: blur(14px);
                    object-fit: cover;
                }
            }

            &__favorite {
                position: absolute;
                top: 1em;
                right: 1.5em;
                transition: transform 200ms ease;
                filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));

                &:hover {
                    transform: scale(1.2);
                }
            }

            &__label {
                position: absolute;
                top: 10px;
                left: 10px;
                color: ${vars.colorTextWhite};
                font-size: 1em;
                padding: 0.4em 1.5em;
                border-radius: 5px;
                background-color: ${vars.backgroundLabel};
                box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
            }

            &__date {
                display: flex;
                flex-direction: column;
                font-weight: bold;
                font-family: ${vars.fontSecondary};
                background-color: ${vars.backgroundWhiteA80};
                font-size: 1.5em;
                padding: 0.3em 1em;
                position: absolute;
                bottom: 10px;
                right: 10px;
                border-radius: 5px;
                box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);

                small {
                    font-size: 14px;
                }
            }
        }

        &__content {
            position: relative;
            padding: 2em;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 1.2em;
            height: calc(100% - 226px);

            &__title {
                font-size: 1.5em;
                font-weight: bold;
                max-width: 320px;
                margin: 0 auto auto;
                cursor: pointer;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &__price {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 8px;

                .price {
                    line-height: 1;
                    font-size: 3.3em;
                    font-weight: bold;
                    color: ${vars.colorTertiary};
                }
            }

            &__cta {
                .layout-button {
                    .button {
                        padding: 1em 5em;

                        @media (pointer: fine) {
                            &:hover {
                                filter: brightness(120%);
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .course {
            flex: 1 1 320px;
            max-width: 300px;
        }
    }
`;
