import moment, { Moment } from 'moment';

import ApiCourse from 'types/api/Course';
import { Option } from 'types/options';

import CourseCategory from 'models/CourseCategory';
import CoursePartner from 'models/CoursePartner';
import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

import { getFormattedDate } from 'utils/date';
import { changeFromCent, displayPrice } from 'utils/math';

import CourseSchedule from './CourseSchedule';

export enum CourseDetailsDataTypes {
    Program = 'program',
    ForWhom = 'forWhom',
    WhatGet = 'whatGet',
}
export interface CourseDetailsData {
    type: CourseDetailsDataTypes;
    content: string;
    order: number;
    courseId?: string;
    id?: string;
}
export interface CourseDetails {
    forWhom: CourseDetailsData[];
    program: CourseDetailsData[];
    whatGet: CourseDetailsData[];
}
export default class Course implements Model {
    id: string;
    name: string;
    slug: string;
    description: string;
    category: CourseCategory;
    partner: CoursePartner;
    isLiked?: boolean;
    priceFrom: number;
    image: FileUpload;
    images: FileUpload[];
    scheduleFrom: Moment;
    scheduleFromDisplay: string;
    priceFromDisplay: string;
    courseSchedules: CourseSchedule[] | null;
    details: CourseDetails;

    constructor(data: ApiCourse) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.slug = data.slug;
        this.category = data.category && new CourseCategory(data.category);
        this.partner = data.partner && new CoursePartner(data.partner);
        this.isLiked = data.isLiked;
        this.image = data.image && new FileUpload(data.image);
        this.images = data.images && data.images.map((image) => new FileUpload(image));
        this.scheduleFrom = data.scheduleFrom && moment.utc(data.scheduleFrom).local();
        this.scheduleFromDisplay = this.scheduleFrom && getFormattedDate(this.scheduleFrom, 'datetime');
        this.priceFrom = changeFromCent(data.priceFrom);
        this.priceFromDisplay = displayPrice(this.priceFrom);
        this.courseSchedules = Array.isArray(data.courseSchedules) ? data.courseSchedules.map(schedule => new CourseSchedule(schedule)) : null;
        this.details = data.details;
    }

    getOption = (): Option<Course> => {
        return {
            value: this,
            label: this.name,
        };
    };
}
