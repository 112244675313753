import ApiCustomerLikedCourse from 'types/api/CustomerLikedCourse';
import { Option } from 'types/options';

import Course from 'models/Course';
import Model from 'models/Model';

export default class CustomerLikedCourse implements Model {
    id: string;
    course: Course;

    constructor(data: ApiCustomerLikedCourse) {
        this.id = data.id;
        this.course = data.course && new Course(data.course);
    }

    getOption = (): Option<CustomerLikedCourse> => {
        return {
            value: this,
            label: this.course?.name,
        };
    };
}